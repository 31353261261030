import { useState, useEffect } from "react";

function WordGame() {
  
  const [gameData] = useState([
    {
      word: "serene",
      clue: "Extremely calm, peaceful, and untroubled. A state of quiet tranquility.",
    },
    {
      word: "eloquent",
      clue: "Fluent and persuasive in speaking or writing.",
    },
    {
      word: "cajole",
      clue: "Coax or persuade someone using flattery or deception.",
    },
    {
      word: "hustle",
      clue: "Move hurriedly or busily.",
    },
    {
      word: "forgery",
      clue: "The act of producing a fake or altered object.",
    },
    {
      word: "flourish",
      clue: "Grow or develop rapidly in a healthy way.",
    },
  ]);

  const [guess, setGuess] = useState("GuessMe");
  const [letters, setLetters] = useState(Array(7).fill(""));
  const [win, setWin] = useState(false);
  const [word, setWord] = useState('')
  const [clue, setClue] = useState('')
  const [started, setStarted] = useState(false)
  const [game, setGame] = useState(gameData[Math.floor(Math.random() * gameData.length)])


  const handleGuess = (e) => {
    setGuess(e.target.value);
  };

  useEffect(() => {

    setLetters(guess.split());
    if (guess.toLowerCase() === word.toLowerCase()) {
      setWin(true);
    }
    if (!guess) {
      setGuess("GuessMe");
    }
    if (!started) {
        setWin(false)
        setGuess("GuessMe")
        setGame(gameData[Math.floor(Math.random() * gameData.length)])
        setWord(game.word)
        setClue(game.clue)
        setStarted(true)
    }
  }, [guess, word, started, clue, game, gameData]);

  return (
    <div>
      <div>
        <p>{clue}</p>
      </div>
      <div className="letter-boxes">
        {letters.map((letter, index) => (
          <div key={index} className="letter-box">
            {letter}
          </div>
        ))}
      </div>
      {!win && <input onChange={handleGuess} maxLength={word.length} />}

      {win && (
        <div>
          <p>That's right, the answer is {word}!</p>
          <button onClick={() => setStarted(false)}>Play Again</button>
        </div>
      )}
    </div>
  );
}

export default WordGame;
