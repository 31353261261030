import "./App.css";
import WordGame from "./components/WordGame";

function App() {
  return (
    <div className="App">
      <h1>Addictionary</h1>
      <WordGame />
    </div>
  );
}

export default App;
